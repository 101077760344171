var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('v-divider'), _c('DatatableWrapper', {
    attrs: {
      "pagination": _vm.pagination,
      "loading": _vm.releaseLoading,
      "clickable-row": ""
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.releases,
      "hide-default-footer": "",
      "mobile-breakpoint": "0",
      "hide-default-header": ""
    },
    on: {
      "page-count": function pageCount($event) {
        _vm.pageCount = $event;
      },
      "click:row": function clickRow($event) {
        return _vm.navigateToDetailPage($event.id);
      },
      "toggle-select-all": _vm.selectAllToggle
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('thead', [_c('tr', _vm._l(_vm.headers, function (header) {
          return _c('th', {
            key: header.text,
            class: "text-".concat(header.align)
          }, [header.value !== 'release' ? _c('span', [_vm._v(" " + _vm._s(header.text) + " ")]) : _vm._e(), header.value === 'archive' ? _c('v-btn', {
            attrs: {
              "icon": ""
            },
            on: {
              "click": function click($event) {
                _vm.showAutoArchiveLearnMore = true;
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            },
            domProps: {
              "textContent": _vm._s('mdi-help-circle-outline')
            }
          })], 1) : _vm._e(), header.value === 'status' ? _c('v-btn', {
            attrs: {
              "icon": ""
            },
            on: {
              "click": function click($event) {
                _vm.showReleaseStatusLearnMore = true;
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            },
            domProps: {
              "textContent": _vm._s('mdi-help-circle-outline')
            }
          })], 1) : _vm._e(), _c('v-row', {
            attrs: {
              "align": "center"
            }
          }, [header.value === 'release' && _vm.canManageRelease ? _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-checkbox', {
            model: {
              value: _vm.selectAll,
              callback: function callback($$v) {
                _vm.selectAll = $$v;
              },
              expression: "selectAll"
            }
          })], 1) : _vm._e(), !_vm.selectedItem.length && header.value === 'release' ? _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_vm._v(" Release ")]) : _vm._e(), _vm.selectedItem.length && header.value === 'release' ? _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_vm.selectedItem.length ? _c('v-btn', {
            staticClass: "text-transform-none mr-2",
            attrs: {
              "color": "warning",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.openActionConfirmation('archive');
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "left": "",
              "small": ""
            }
          }, [_vm._v("archive ")]), _vm._v(" Archive ("), _c('b', [_vm._v(_vm._s(_vm.selectedItem.length))]), _vm._v(") ")], 1) : _vm._e(), _vm.selectedItem.length ? _c('v-btn', {
            staticClass: "text-transform-none",
            attrs: {
              "color": "error",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.openActionConfirmation('delete');
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "left": "",
              "small": ""
            }
          }, [_vm._v("cancel ")]), _vm._v(" Delete ("), _c('b', [_vm._v(_vm._s(_vm.selectedItem.length))]), _vm._v(") ")], 1) : _vm._e()], 1) : _vm._e()], 1)], 1);
        }), 0)])];
      },
      proxy: true
    }, {
      key: "item.release",
      fn: function fn(_ref) {
        var _item$meta;
        var item = _ref.item;
        return [_c('v-row', {
          staticClass: "cursor-pointer",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "mr-5",
          attrs: {
            "cols": "auto"
          }
        }, [_vm.canManageRelease ? _c('v-simple-checkbox', {
          staticClass: "mr-n14",
          attrs: {
            "disabled": ![1, 2, 3].includes(item === null || item === void 0 ? void 0 : item.status),
            "readonly": ![1, 2, 3].includes(item === null || item === void 0 ? void 0 : item.status),
            "value": _vm.isSelected(item)
          },
          on: {
            "input": function input($event) {
              return _vm.select(item);
            }
          }
        }) : _vm._e()], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', [_c('img', {
          attrs: {
            "src": item.image || "/media/defaults/".concat(_vm.$vuetify.theme.dark ? 'app_default_light' : 'app_default', ".svg"),
            "height": "40"
          },
          on: {
            "error": _vm.replaceDefaultReleaseImage
          }
        })])]), _c('v-col', [_c('release-chip', {
          attrs: {
            "release": Object.assign({}, item.info, {
              status: item === null || item === void 0 ? void 0 : item.status,
              platform: item.platform
            }),
            "version-length": 11
          }
        }), item !== null && item !== void 0 && (_item$meta = item.meta) !== null && _item$meta !== void 0 && _item$meta.latest ? _c('div', {
          staticClass: "smallFont info--text"
        }, [_vm._v(" Latest Release ")]) : _vm._e()], 1)], 1)];
      }
    }, {
      key: "item.size",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.formatBytes(item.info.size)) + " ")];
      }
    }, {
      key: "item.developer",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('user-avatar', {
          attrs: {
            "user-detail": item.user
          }
        })];
      }
    }, {
      key: "item.download",
      fn: function fn(_ref4) {
        var _item$stats3, _item$stats4;
        var item = _ref4.item;
        return [_vm.canManageApp ? _c('span', [_c('div', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$router.push({
                name: 'release-install',
                params: {
                  app_id: _vm.$route.params.app_id,
                  release_id: item.id
                }
              });
            }
          }
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var _item$stats, _item$stats2;
              var on = _ref5.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "color": "primary",
                  "icon": ""
                }
              }, on), [_c('b', [_vm._v(_vm._s(_vm.numberWithCommas(((item === null || item === void 0 ? void 0 : (_item$stats = item.stats) === null || _item$stats === void 0 ? void 0 : _item$stats.installs) || 0) + ((item === null || item === void 0 ? void 0 : (_item$stats2 = item.stats) === null || _item$stats2 === void 0 ? void 0 : _item$stats2.downloads) || 0))))])])];
            }
          }], null, true)
        }, [_c('span', [_c('div', [_vm._v(" Total downloads: " + _vm._s(_vm.numberWithCommas((item === null || item === void 0 ? void 0 : (_item$stats3 = item.stats) === null || _item$stats3 === void 0 ? void 0 : _item$stats3.downloads) || 0)) + " ")]), _c('div', [_vm._v(" Total install: " + _vm._s(_vm.numberWithCommas((item === null || item === void 0 ? void 0 : (_item$stats4 = item.stats) === null || _item$stats4 === void 0 ? void 0 : _item$stats4.installs) || 0)) + " ")]), _c('div', {
          staticClass: "my-3"
        }, [_vm._v(" We are updating this info every minute. ")])])])], 1)]) : _c('span', [_vm._v(" - ")])];
      }
    }, {
      key: "item.comments",
      fn: function fn(_ref6) {
        var _item$stats5;
        var item = _ref6.item;
        return [_vm.canManageApp ? _c('span', [_c('v-btn', {
          attrs: {
            "color": "primary",
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$router.push({
                name: 'release-comment-list',
                params: {
                  app_id: _vm.$route.params.app_id,
                  release_id: item.id
                }
              });
            }
          }
        }, [_c('b', [_vm._v(" " + _vm._s(_vm.numberWithCommas(item === null || item === void 0 ? void 0 : (_item$stats5 = item.stats) === null || _item$stats5 === void 0 ? void 0 : _item$stats5.comments)) + " ")])])], 1) : _c('span', [_vm._v(" - ")])];
      }
    }, {
      key: "item.archive",
      fn: function fn(_ref7) {
        var _item$meta2;
        var item = _ref7.item;
        return [item.archive_at ? _c('div', [item.archive_at ? _c('vue-hoverable-date', {
          attrs: {
            "date": item.archive_at,
            "info": (item === null || item === void 0 ? void 0 : (_item$meta2 = item.meta) === null || _item$meta2 === void 0 ? void 0 : _item$meta2.archive_reason) || 'Auto archived'
          }
        }) : _vm._e()], 1) : _c('div', [_c('vue-tooltip', {
          attrs: {
            "displayText": "-",
            "text": "No expiry date"
          }
        })], 1)];
      }
    }, {
      key: "item.date",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('vue-hoverable-date', {
          attrs: {
            "date": item.created_at
          }
        })];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref9) {
        var _vm$$options$STATUS$i2, _vm$$options$STATUS$i3, _vm$$options$STATUS$i4, _vm$$options$STATUS$i5;
        var item = _ref9.item;
        return [_c('div', [[4, 5].includes(item.status) ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref10) {
              var _vm$$options$STATUS$i;
              var on = _ref10.on;
              return [_c('v-progress-circular', _vm._g({
                attrs: {
                  "color": (_vm$$options$STATUS$i = _vm.$options.STATUS[item === null || item === void 0 ? void 0 : item.status]) === null || _vm$$options$STATUS$i === void 0 ? void 0 : _vm$$options$STATUS$i.color,
                  "size": 15,
                  "width": 2,
                  "indeterminate": ""
                }
              }, on))];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s((_vm$$options$STATUS$i2 = _vm.$options.STATUS[item === null || item === void 0 ? void 0 : item.status]) === null || _vm$$options$STATUS$i2 === void 0 ? void 0 : _vm$$options$STATUS$i2.title) + " ")])]) : _c('vue-tooltip', {
          attrs: {
            "icon-color": (_vm$$options$STATUS$i3 = _vm.$options.STATUS[item === null || item === void 0 ? void 0 : item.status]) === null || _vm$$options$STATUS$i3 === void 0 ? void 0 : _vm$$options$STATUS$i3.color,
            "text": (_vm$$options$STATUS$i4 = _vm.$options.STATUS[item === null || item === void 0 ? void 0 : item.status]) === null || _vm$$options$STATUS$i4 === void 0 ? void 0 : _vm$$options$STATUS$i4.title,
            "icon-text": (_vm$$options$STATUS$i5 = _vm.$options.STATUS[item === null || item === void 0 ? void 0 : item.status]) === null || _vm$$options$STATUS$i5 === void 0 ? void 0 : _vm$$options$STATUS$i5.icon
          }
        })], 1)];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('action-dropdown', {
          scopedSlots: _vm._u([{
            key: "list",
            fn: function fn() {
              return [_c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.navigateToDetailPage(item.id);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-eye")]), _vm._v(" View release ")], 1), [1].includes(item === null || item === void 0 ? void 0 : item.status) ? _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.onReleaseFeedbackForm(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-comment")]), _vm._v(" Post Comment ")], 1) : _vm._e(), _vm.canManageRelease && [2, 3].includes(item === null || item === void 0 ? void 0 : item.status) ? _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.onDeleteClick(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("cancel")]), _vm._v(" Delete ")], 1) : _vm._e(), _vm.canManageRelease && [1].includes(item === null || item === void 0 ? void 0 : item.status) ? _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.onArchivedClicked(item, 'fromList');
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("archive")]), _vm._v(" Archive ")], 1) : _vm._e(), [1].includes(item === null || item === void 0 ? void 0 : item.status) ? _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.onDownloadClick(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("get_app")]), _vm._v(" Download app file ")], 1) : _vm._e()];
            },
            proxy: true
          }], null, true)
        })];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('div', [_c('no-data', {
          attrs: {
            "align-class": "text-left",
            "btn-icon": "",
            "btn-text": "New Releases",
            "first-text": "No Releases, Just Yet...",
            "second-text": "<ul><li><strong>1. Upload</strong>: Add your APK or IPA to notify team members and guests.</li><li><strong>2. Installation</strong>: The release can be installed via our app or the public install page.</li><li><strong>3. Automate</strong>: Streamline your process with <a href='https://help.testapp.io/topic/integrations/' target='_blank'>our integrations</a>. Automate release uploads, notifications, and more to save time and ensure consistency.</li></ul>"
          }
        })], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  })], 1)], 1), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": "800"
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.showReleaseCommentForm ? _c('v-card', [_c('v-card-title', [_c('div', [_c('div', [_vm._v("Post Comment")])]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.showReleaseCommentForm = false;
            }
          }
        }, [_c('v-icon', {
          domProps: {
            "textContent": _vm._s('mdi-close')
          }
        })], 1)], 1), _c('v-card-text', {
          staticClass: "pa-6"
        }, [_c('feedback-form', {
          attrs: {
            "release-info": _vm.selectedRelease,
            "from-release-page": ""
          },
          on: {
            "close": function close($event) {
              _vm.showReleaseCommentForm = false;
            }
          }
        })], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showReleaseCommentForm,
      callback: function callback($$v) {
        _vm.showReleaseCommentForm = $$v;
      },
      expression: "showReleaseCommentForm"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Delete release ")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "my-4 message"
        }, [_c('p', [_vm._v(" Are you sure you want to " + _vm._s(_vm.selectedItem.length > 1 ? " archive and" : "") + " delete " + _vm._s(_vm.selectedItem && !_vm.selectedItem.length ? "this" : "the selected (" + _vm.selectedItem.length + ")") + " " + _vm._s(_vm.selectedItem.length > 1 ? " releases" : " release") + "? ")]), _c('v-alert', {
          staticClass: "mt-3",
          attrs: {
            "border": "left",
            "color": "info",
            "icon": "info",
            "outlined": ""
          }
        }, [_vm._v(" Archived releases are not available for installation and are not counted towards your storage quota. "), _c('br'), _c('br'), _vm._v(" They are there for your future reference. ")]), _c('v-alert', {
          staticClass: "mt-3",
          attrs: {
            "border": "left",
            "color": "error",
            "icon": "error",
            "outlined": ""
          }
        }, [_vm._v(" Deleted releases will be removed from: "), _c('br'), _c('br'), _c('ul', [_c('li', [_vm._v("Releases page")]), _c('li', [_vm._v("Installs page")]), _c('li', [_vm._v("Share page")]), _c('li', [_vm._v("Total releases stats (app and team)")])])])], 1), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('div', [_vm._v("No undo for this action")]), _c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.onCancel
          }
        }, [_vm._v("No ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "loading": _vm.loading,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.onConfirm
          }
        }, [_vm._v("Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modal,
      callback: function callback($$v) {
        _vm.modal = $$v;
      },
      expression: "modal"
    }
  }), _c('Modal', {
    attrs: {
      "warning": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        var _vm$getTeamPlanUsage, _vm$getTeamPlanUsage2;
        return [_vm.archiveModal ? [_c('v-card', {
          staticClass: "my-n3"
        }, [_c('v-card-title', [_c('div', {
          staticClass: "darkGrey--text bold-text"
        }, [_vm._v("Archive release")])]), _c('v-divider'), _c('v-card-text', {
          staticClass: "mt-4 message"
        }, [_vm._v(" Are you sure you want to archive " + _vm._s(_vm.selectedItem && !_vm.selectedItem.length ? "this release?" : "these selected releases?") + " "), _c('v-alert', {
          staticClass: "mt-3",
          attrs: {
            "border": "left",
            "color": "info",
            "icon": "info",
            "outlined": ""
          }
        }, [_vm._v(" By archiving releases, we will be deleting the (.apk / .ipa) "), _c('br'), _c('br'), _vm.archiveReadmore ? _c('div', [_vm._v(" Those releases will not be visible or available for installation in our app and public install links."), _c('br'), _c('br'), _vm._v(" Keeping all the data attached to these releases such as notes, installs, feedback,... for your future reference. ")]) : _vm._e(), !_vm.archiveReadmore ? _c('b', {
          staticClass: "pointer",
          on: {
            "click": function click($event) {
              _vm.archiveReadmore = !_vm.archiveReadmore;
            }
          }
        }, [_vm._v(" " + _vm._s("Read more...") + " ")]) : _vm._e()]), _c('v-alert', {
          staticClass: "mt-3",
          attrs: {
            "border": "left",
            "color": "success",
            "icon": "info",
            "outlined": ""
          }
        }, [_vm._v(" Archived releases will not be counted towards your team's storage quota 👍 ")])], 1), _c('v-divider'), _c('v-card-text', {
          staticClass: "pt-3"
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "py-2",
          attrs: {
            "cols": !((_vm$getTeamPlanUsage = _vm.getTeamPlanUsage) !== null && _vm$getTeamPlanUsage !== void 0 && _vm$getTeamPlanUsage.auto_archive_release) ? 10 : 12
          }
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-checkbox', {
          attrs: {
            "disabled": !((_vm$getTeamPlanUsage2 = _vm.getTeamPlanUsage) !== null && _vm$getTeamPlanUsage2 !== void 0 && _vm$getTeamPlanUsage2.auto_archive_release),
            "value": 0,
            "hide-details": "",
            "label": "Now"
          },
          model: {
            value: _vm.noValue,
            callback: function callback($$v) {
              _vm.noValue = $$v;
            },
            expression: "noValue"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', [_c('v-text-field', {
          attrs: {
            "disabled": _vm.noValue || !_vm.getTeamPlanUsage.auto_archive_release,
            "filled": "",
            "hide-details": "",
            "type": "number"
          },
          model: {
            value: _vm.selectedDay,
            callback: function callback($$v) {
              _vm.selectedDay = $$v;
            },
            expression: "selectedDay"
          }
        })], 1), _c('v-col', [_c('v-select', {
          attrs: {
            "disabled": _vm.noValue || !_vm.getTeamPlanUsage.auto_archive_release,
            "items": ['Days', 'Months'],
            "filled": "",
            "hide-details": ""
          },
          model: {
            value: _vm.selectedTime,
            callback: function callback($$v) {
              _vm.selectedTime = $$v;
            },
            expression: "selectedTime"
          }
        })], 1), !_vm.getTeamPlanUsage.auto_archive_release ? _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref12) {
              var on = _ref12.on;
              return [_c('span', _vm._g({}, on), [_c('v-btn', {
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    _vm.showStartTrialModal = true;
                  }
                }
              }, [_c('v-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "color": "primary"
                },
                domProps: {
                  "textContent": _vm._s('mdi-lock-outline')
                }
              })], 1)], 1)];
            }
          }], null, false, 3046267260)
        }, [_c('span', [_vm._v("Auto archive settings is available for Starter & Pro team plans")])])], 1) : _vm._e(), _c('v-col', {
          staticClass: "py-0",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Note: Maximum 90 days or 3 months ")])], 1)], 1)], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', [_vm._v(" You can change this setting for future releases from the "), _c('span', {
          staticClass: "primary--text cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.$router.push("/app/".concat(_vm.$route.params.app_id, "/settings/releases"));
            }
          }
        }, [_vm._v("app settings")]), _vm._v(" page ")])])], 1)], 1), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "mr-2 text-transform-none",
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.onCancelArchive
          }
        }, [_vm._v("No ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "loading": _vm.loading,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.onConfirmArchive
          }
        }, [_vm._v("Yes ")])], 1)], 1)] : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.archiveModal,
      callback: function callback($$v) {
        _vm.archiveModal = $$v;
      },
      expression: "archiveModal"
    }
  }), _c('Modal', {
    attrs: {
      "closeable": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('auto-archive-information')];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showAutoArchiveLearnMore,
      callback: function callback($$v) {
        _vm.showAutoArchiveLearnMore = $$v;
      },
      expression: "showAutoArchiveLearnMore"
    }
  }), _c('Modal', {
    attrs: {
      "closeable": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        var _vm$$options;
        return [_c('v-card', [_c('v-card-title', [_c('div', [_vm._v("Status")])]), _c('v-card-text', [_c('v-row', _vm._l((_vm$$options = _vm.$options) === null || _vm$$options === void 0 ? void 0 : _vm$$options.STATUSDESC, function (status, index) {
          return _c('v-col', {
            key: index,
            staticClass: "bodyFont",
            attrs: {
              "cols": "12"
            }
          }, [_c('div', [_c('v-icon', {
            attrs: {
              "color": status.color,
              "left": ""
            },
            domProps: {
              "textContent": _vm._s(_vm.$options.STATUS[status === null || status === void 0 ? void 0 : status.value].icon || 'mdi-radiobox-marked')
            }
          }), _c('span', {
            staticClass: "semi-bold"
          }, [_vm._v(" " + _vm._s(status.title) + " ")])], 1), _c('div', {
            staticClass: "ml-8"
          }, [_vm._v(" " + _vm._s(status.desc) + " ")])]);
        }), 1)], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showReleaseStatusLearnMore,
      callback: function callback($$v) {
        _vm.showReleaseStatusLearnMore = $$v;
      },
      expression: "showReleaseStatusLearnMore"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }